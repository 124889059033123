<template>
    <Background class="wrapper">
        <div class="form-wrapper">
            <div class="form login">
                <Card>
                    <AuthBanner v-if="isCompetition"></AuthBanner>
                    <SignIn v-if="routerPath === 'signIn'"></SignIn>
                    <SignUp v-if="routerPath === 'signUp'"></SignUp>
                    <ResetPasswordWrapper v-if="routerPath === 'reset'"></ResetPasswordWrapper>
                </Card>
            </div>
        </div>
    </Background>
</template>

<script>
  import Card from "../Card";
  import Background from "./Background";
  import AuthBanner from "@/components/Common/Auth/AuthBanner";
  import SignIn from "@/components/Common/Auth/SignIn";
  import SignUp from "@/components/Common/Auth/SignUp";
  import ResetPasswordWrapper from "@/components/Common/Auth/ResetPasswordWrapper";

  export default {
    name: "Auth",
    components: {
      Background,
      Card,
      AuthBanner,
      SignIn,
      SignUp,
      ResetPasswordWrapper
    },

    computed: {
      isCompetition () {
        return /*this.$store.state.dictionaries.currentCompetition?.id*/ false;
      },

      routerPath () {
        return this.$route.path.replaceAll("/", "");
      }
    },

    methods: {
      captchaExecute () {
        this.$refs.captcha.$refs.hcaptcha.execute();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F2F4FD;
    overflow-x: hidden;
  }

  .form {
    @media screen and (max-width: 825px) {
      min-width: 0;
    }

    .notification {
      left: 50%;
      width: 100%;
      padding: 1em 1.10295em;
      font-size: 0.85em;
      max-width: 500px;
      transform: translateX(-50%);
    }

    .content {
      max-width: 470px;
      padding: 3rem 4rem;
      @media screen and (max-width: 825px) {
        width: 100%;
        min-width: auto;
        padding: 2rem;
      }
    }

    .banner {
      width: 50%;
      max-width: 470px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      ::v-deep {
        .b-image-wrapper {
          margin: 30px 0;
        }
      }
    }

    ::v-deep {
      .card {
        box-shadow: 0px 4px 10px rgba(216, 216, 216, 0.5);

        .card-content {
          display: flex;
          padding: 0;
          width: 100%;
          max-width: calc(640px + 20vw);

          @media screen and (max-width: 725px) {
            //width: max-content;
          }

          .input {
            // Перебивает изменение цвета автозаполнения
            input:-webkit-autofill {
              &, &:hover, &:focus, &:active {
                transition: background-color 5000s ease-in-out 0s;
              }
            }
          }

          .button.is-text {
            color: $info;

            &:hover {
              background: none;
              color: transparentize($info, .2);
            }
          }
        }
      }
    }
  }
</style>